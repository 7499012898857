<template>
  <b-card-code>
    <b-form @submit.prevent="save(item)">
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Código"
            label-for="code"
          >
            <b-form-input
              id="code"
              v-model="item.auxCode"
              class="text-uppercase"
              maxlength="4"
              minlength="4"
              required
              @input="(val) => (item.auxCode = item.auxCode.toUpperCase())"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Centro de Costo"
            label-for="costCenter"
          >
            <v-select
              id="costCenter"
              v-model="item.centerId"
              label="centro"
              :options="costCenters"
              :reduce="option => option.idCentro"
              required
              @input="selectedCenter => {filterAccounts(selectedCenter);item.accountId=null}"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Cuenta Contable"
            label-for="acc"
          >
            <v-select
              id="acc"
              v-model="item.accountId"
              label="cuenta"
              :options="accountsFiltered"
              :reduce="option => option.idCuenta"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Tipo"
            label-for="typeT"
          >
            <v-select
              id="typeT"
              v-model="item.auxType"
              label="label2"
              :options="[{label2: 'Numérico', type: 'N'}, {label2: 'Porcentual', type: 'P'},]"
              :reduce="option => option.type"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Clase"
            label-for="class"
          >
            <v-select
              id="class"
              v-model="item.auxClass"
              label="label3"
              :options="[{label3: 'Manual', clase: 'M'},{label3: 'Automático', clase: 'A'}]"
              :reduce="option => option.clase"
              required
              @input="classTypeView(item.auxClass)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="classShowOptions === 'A'">
        <b-col cols="6">
          <b-form-group
            label="Periodo"
            label-for="period"
          >
            <b-form-input
              id="period"
              v-model="item.testPeriod"
              type="number"
              max="203001"
              placeholder="Ingrese Periodo"
              required
            />
            <p
              v-if="item.testPeriod > 203001"
              class="font-small-1 text-danger"
            >
              Revise el Periodo
            </p>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="classShowOptions === 'A'">
        <b-col cols="6">
          <b-form-group
            label="Comando"
            label-for="command"
          >
            <b-form-textarea
              id="command"
              v-model="item.sqlStmt"
              max="6"
              placeholder="Ingrese comando SQL"
              required
            />
          </b-form-group>
          <b-alert
            v-if="sqlResponse"
            :variant="themeC"
            show
          >
            <div class="alert-body">
              <span class="font-small-2">{{ sqlResponse }}</span>
            </div>
          </b-alert>
        </b-col>
        <b-col cols="6">
          <b-button
            variant="outline-primary"
            class="mt-2"
            size="sm"
            @click="sqlTest()"
          >
            Probar
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Descripción"
            label-for="description"
          >
            <b-form-input
              id="code"
              v-model="item.auxDescription"
              placeholder="Ingrese una descripción"
              required
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group
            label="Estado"
            label-for="status"
          >
            <v-select
              id="status"
              v-model="item.status"
              label="status"
              :options="statuses"
              :reduce="option => option.idStatus"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <!-- submit and reset -->
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            :disabled="loading"
          >
            <span v-if="!loading">Guardar</span>
            <span v-if="loading">Un momento...</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
            :to="'/portal/admin/auxiliary-list'"
          >
            Cancelar
          </b-button>
        </b-col>

        <p>{{ errorMessage }}</p>
      </b-row>
    </b-form>
  </b-card-code>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton, BFormTextarea, BAlert,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import BCardCode from '@core/components/b-card-code'
import environment from '@/environment'

export default {
  name: 'CentersTypesAddVue',
  components: {
    BAlert,
    BFormTextarea,
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      item: {accountId:null},
      loading: false,
      errorMessage: '',
      sqlResponse: '',
      costCenters:[],
      accountsFiltered: [],
      statuses: [],
      classShowOptions: true
    }
  },
  created() {
    this.catalogLoad()
  },
  methods: {
    async catalogLoad(){
      const dataAccounts = await this.$http.get(`${environment.uri}/accounts/allx`)
      this.accounts = dataAccounts.data
      const costCenters= await this.$http.get(`${environment.uri}/ccenters/all`)
      this.costCenters = costCenters.data
      const dataGerencia = await this.$http.get(`${environment.uri}/mngmts/all`)
      this.gerenciasDirectivas = dataGerencia.data
      this.statuses = this.$store.getters['catalog/allStatuses']
    },

    filteredCostCenters(e){
      this.filteredCC = this.costCenters.filter(field => field.idGerencia === e.idGerencia)
    },

    filterAccounts(selectedCenter) {
      this.accountsFiltered = this.accounts.filter(acc => acc.idCentro === selectedCenter)
    },

    classTypeView(e){
      if(e === 'A'){
        this.classShowOptions = 'A'
        this.item.testPeriod = null
        this.item.sqlStmt = null

      }
      else {
        let periodo = parseInt((new Date().getFullYear()).toString() + ((new Date().getMonth()) + 1).toString())
        this.classShowOptions = 'M'
        this.item.testPeriod = periodo
        this.item.sqlStmt = 'SELECT GETDATE()'
      }
    },

    save(item) {
      this.loading = true
      this.errorMessage = ''
      this.$http.post(`${environment.uri}/auxiliaries/create`, item)
          .then(() => {
            this.loading = false
            this.$router.replace('/portal/admin/auxiliary-list')
          })
          .catch(error => {
            console.error(error)
            this.errorMessage = (error && error.apiMessage) ? error.apiMessage : 'No fue posible procesar su solicitud'
            this.loading = false
          })
    },

    async sqlTest() {
      if (this.item.auxCode && this.item.testPeriod  && this.item.sqlStmt) {
        try {
          this.loading = true
          await this.$http.post(`${environment.uri}/auxiliaries/validate/sql`,
              {
                "auxCode": this.item.auxCode,
                "period": this.item.testPeriod,
                "command": this.item.sqlStmt
              }).then(response => {
            const respuesta = response.data
            this.sqlResponse = respuesta.message
            this.themeC = 'success'
            console.log('respuesta: ', respuesta)
          })

        } catch (error) {
          console.error('ver-error', error)
          this.sqlResponse = error.response.data
          this.themeC = 'danger'
        } finally {
          this.loading = false
        }
      }
      else {
        this.sqlResponse = 'Código Auxiliar, Periodo y Comando SQL deben ser ingresados'
        this.themeC = 'danger'
        console.log('hoa')
      }
    }
  }
}
</script>

<style scoped>

</style>
